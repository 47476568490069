<template>
  <div class="layout-config h-100 p-10">
    <Row type="flex" :gutter="10">
      <Col span="4">
        <SelectDict
          v-model="param.resourceType"
          :dict="dict.resourceType"
          placeholder="请选择资源类型"
        />
      </Col>
      <Col span="4">
        <Button type="primary" @click="getData()">查询</Button>
        <Button
          type="success"
          class="m-l-5"
          :to="
            `/config/dataManage/activity/activityResourcePage?id=${$route.query.id}`
          "
          >添加资源</Button
        >
      </Col>
      <Col span="16" class="text-r">
        <Button type="error" @click="$router.back()">返回</Button>
      </Col>
    </Row>
    <Table
      border
      :columns="cols"
      :data="table"
      :max-height="520"
      ref="table"
      class="m-t-10"
    />
    <div class="text-r m-t-10 p-r-10">
      共
      <span class="f-bold m-l-5 m-r-5">{{ `${table.length}` }}</span>
      条
    </div>
  </div>
</template>

<script>
import { getResource, delResource } from "@/libs/api/activity";
import { dict } from "@/config";
import { mapState } from "vuex";
export default {
  data() {
    const _this = this;
    return {
      activityId: null,
      dict,
      param: {},
      table: [],
      cols: [
        {
          title: "序号",
          type: "index",
          key: "index",
          width: 70,
          align: "center",
        },
        {
          title: "资源类型",
          key: "resourceType",
          width: 100,
          render(h, w) {
            return h("span", dict.resourceType[w.row.resourceType]);
          },
        },
        {
          title: "资源内容",
          key: "context",
          tooltip: true,
          ellipsis: true,
          render(h, w) {
            if (w.row.resourceType == "pan/text") {
              return h(
                "Tooltip",
                {
                  class: "w-100",
                  props: {
                    transfer: true,
                    placement: "bottom-start",
                  },
                },
                [
                  h(
                    "div",
                    {
                      slot: "content",
                      style: {
                        whiteSpace: "normal",
                        wordBreak: "break-all",
                        overflow: "hidden",
                      },
                    },
                    w.row.context
                  ),
                  h(
                    "div",
                    {
                      class: "ellipsis w-100",
                    },
                    w.row.context
                  ),
                ]
              );
            } else if (w.row.resourceType == "pan/image") {
              return h('PhotoView', {
                class: 'm-t-5',
                props: {
                  width: 100,
                  height: 50,
                  value: w.row.context
                }
              })
            } else if (w.row.resourceType == "pan/video") {
              return h('VideoView', {
                props: {
                  width: 100,
                  height: 50,
                  value: w.row.context
                }
              })
            } else {
              return h('span', w.row.context)
            }
          },
        },
        { title: "所属用户", key: "userId", width: 150 },
        {
          title: "操作",
          width: 200,
          render(h, w) {
            const btns = [];
            if (_this.isAuth) {
              btns.push(
                h(
                  "Button",
                  {
                    props: { type: "error", size: "small" },
                    on: {
                      click() {
                        _this.$Modal.confirm({
                          title: "删除",
                          content: `是否删除当前活动资源?`,
                          onOk: () => {
                            delResource({
                              activity_resource_id: w.row.id,
                            }).then(({ code, data, msg }) => {
                              if (code == "HA0200") {
                                _this.$Message.success("删除成功！");
                                _this.getData();
                              } else {
                                _this.$Message.info(msg || "删除失败！");
                              }
                            });
                          },
                        });
                      },
                    },
                  },
                  "删除"
                )
              );
            }

            return btns;
          },
        },
      ],
    };
  },
  computed: {
    ...mapState({
      isAuth: (state) => state.user.isAuth,
    }),
  },
  methods: {
    getData() {
      getResource({ activity_id: this.activityId }).then(
        ({ code, data, msg }) => {
          if (code == "HA0200") {
            this.table = data;
          }
        }
      );
    },
  },
  mounted() {
    this.activityId = this.$route.query.id;
    this.getData();
  },
};
</script>

<style></style>
